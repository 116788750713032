<template>
  <div>
    <div class="main">
      <img :src="items[active].original_url">
    </div>
    <div class="list">
      <img
        class="item"
        v-for="(item, i) of items"
        :key="i"
        :class="{ 'active': active === i }"
        :src="item.thumbnail_url"
        @click="activate(i)"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    active: null,
  }),

  created() {
    this.active = this.items.length
      ? 0
      : {
        original_url: null,
      };
  },

  methods: {
    activate(i) {
      this.active = i;
    },
  },
};
</script>

<style lang="scss" scoped>
img {
  max-height: 100%;
  max-width: 100%;
}

.main {
  height: 400px;
  text-align: center;

  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
  text-align: center;
  box-sizing: content-box;
}

.item {
  cursor: pointer;
  border: 3px solid transparent;
  display: inline-block;
  height: auto;
  margin: 10px;
  max-height: 150px;
  position: relative;
  text-align: center;

  @media only screen and (max-width: 575px) {
    height: 20vw;
  }

  &.active {
    border-color: #CCC;
  }
}
</style>
