<template>
  <div>
    <div
      class="product"
      :class="{ 'sale': product && product.discount_price }"
    >
      <div class="section_container">
        <div class="container">
          <div class="row">
            <div
              class="col"
              v-if="product"
            >
              <div class="product_content_container d-flex flex-lg-row flex-column align-items-start justify-content-start">
                <div class="product_content">
                  <div class="product_content_inner">
                    <mini-gallery :items="product.images" />
                  </div>
                </div>
                <div class="product_sidebar order-lg-2 order-1">
                  <form action="#" id="product_form" class="product_form">
                    <div class="product_name">{{ product.name }}</div>
                    <div
                      class="product_price"
                      :class="{ 'product_price-discounted': !!product.discount_price }"
                      v-if="product.quantity"
                    >
                      ${{ product.discount_price ? product.discount_price : product.regular_price }}
                      <strike v-if="product.discount_price">${{ product.regular_price }}</strike>
                    </div>
                    <div
                      class="product_price soldout"
                      v-else
                    >
                      SOLD OUT
                    </div>
                    <button
                      class="cart_button trans_200"
                      :class="{ 'disabled': isLocked(product) }"
                      :title="getButtonTooltip(product)"
                      @click.prevent="addToCart(product)"
                      v-if="product.quantity"
                    >
                      add to cart
                    </button>
                    <div class="mt-5" v-html="product.description" />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="related">
      <div class="section_container">
        <div class="container">
          <h3>Related Products</h3>
          <div class="row">
            <div
              class="related-item col-12 col-sm-6 col-lg-4 col-xxl-3 mb-5"
              v-for="(related, r) of relateds"
              :key="r"
              :class="{ 'sale': related.discount_price }"
            >
              <div>
                <router-link
                  class="related_image"
                  :to="`/product/${getProductSlug(related.canonical)}`"
                >
                  <img
                    :src="related.images[0].thumbnail_url"
                    :alt="related.images[0].name"
                  />
                  <div
                    class="related_tag"
                    v-if="related.discount_price"
                  >
                    {{ related.discount_price ? 'sale' : '' }}
                  </div>
                </router-link>
                <div class="related_content">
                  <router-link
                    class="related_title"
                    :to="`/product/${getProductSlug(related.canonical)}`"
                  >
                    {{ related.name }}
                  </router-link>
                  <div
                    class="related_price"
                    v-if="related.quantity"
                  >
                    ${{ related.discount_price ? related.discount_price : related.regular_price }}
                    <strike v-if="related.discount_price">${{ related.regular_price }}</strike>
                  </div>
                  <div
                    class="related_price soldout"
                    v-else
                  >
                    SOLD OUT
                  </div>
                  <div
                    class="related_button trans_200"
                    :class="{ 'disabled': isLocked(related) }"
                    :title="getButtonTooltip(related)"
                    @click="addToCart(related)"
                    v-if="related.quantity"
                  >
                    add to cart
                  </div>
                  <div class="related_button-invisible" v-else />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MiniGallery from '@/components/MiniGallery';

export default {
  name: 'product',

  components: {
    MiniGallery,
  },

  data: () => ({
    product: null,
    relateds: [],
  }),

  mounted() {
    this.fetch();
  },

  methods: {
    async fetch() {
      const { data } = await this.$api.product(this.$route.params.slug);
      this.product = data;
      for (let i = 0; i < this.product.tags.length; i++) {
        this.fetchRelated(this.product.tags[i]);
      }
    },
    async fetchRelated(tag) {
      const { data } = await this.$api.tags(tag);
      this.relateds = [
        ...this.relateds,
        ...data.products,
      ];
    },
    addToCart(product) {
      if (!this.isLocked(product)) {
        this.$store.commit('ADD_TO_CART', product);
      }
    },
    getProductSlug(canonical) {
      const parts = canonical.split('/');
      return parts[parts.length - 1];
    },
    getButtonTooltip: ({ lock_by }) => lock_by
      ? `Please add to cart item "${lock_by}" to purchase this product`
      : false,
    isLocked({ lock_by }) {
      return !!lock_by && !this.$store.state.cart.find(item => item.sku === lock_by);
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/scss/views/product.scss';
</style>
